import React, {useState , useEffect} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from '../firebase.config/firebaseconfig.js'

function Login(){

    const [User, setUser] = useState();
    const [Password, setPassword] = useState();
    const [ErrorMSG, setEMSG] = useState();

    const changeSetUser = e =>{
        setUser(e.target.value)
        console.log(User)
    }

    const changeSetPassword = e =>{
        setPassword(e.target.value)
        console.log(Password)
    }

    const LogIn = () => {
        signInWithEmailAndPassword(auth, User, Password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("Logged");
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setEMSG("El usuario y/o contraseña no coinciden");
          console.log("Fail");
        });
    }

    return(
        <div className="login-page">
            <Container fluid className="login"> 
                <Row className="login-row justify-content-md-center ">
                    <Col sm="12" md="6" className="login-col">
                    <Form className="login-form">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control type="email" onChange={changeSetUser} placeholder="Enter email" />
                          <Form.Text className="text-muted">
                          </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control type="password" onChange={changeSetPassword} placeholder="Password" />
                        </Form.Group>
                        <div className="d-grid gap-2">
                        <div className="error-msg">{ErrorMSG}</div>
                        <Button className="login-btn" variant="warning"size="lg" onClick={LogIn}>
                            Submit
                        </Button>
                        </div>
                    </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Login;