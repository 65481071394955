import React , { useState, useEffect}from "react";

import { signOut } from "firebase/auth";
import { auth } from "../firebase.config/firebaseconfig";
import { db } from "../firebase.config/firebaseconfig";
import { ref, onValue, set, updates} from "firebase/database";

import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function SetUp(props){    

    const [EDA, setEDA] = useState()
    const [EDS, setEDS] = useState()

    const writeEDA = () =>{
        if(EDA == true){
            set(ref(db, 'Usuario/' + props.uid), {
                ESTADO_DE_ALARMA: false,
                ESTADO_DE_FUNCIONAMIENTO: EDS
            });
        }
    }

    const writeEDS =  () =>{
        if(EDS == true){
            set(ref(db, 'Usuario/' + props.uid), {
                ESTADO_DE_ALARMA: false,
                ESTADO_DE_FUNCIONAMIENTO: false
            });
        }
        if(EDS == false){
            set(ref(db, 'Usuario/' + props.uid), {
                ESTADO_DE_ALARMA: false,
                ESTADO_DE_FUNCIONAMIENTO: true
            });
        }
    }

    const LogOut = () =>{
        signOut(auth).then(() => {
          }).catch((error) => {
          });
    }

    useEffect(()=>{
        onValue(ref(db, 'Usuario/' + props.uid), (snapshot) => {
            setEDA(snapshot.val().ESTADO_DE_ALARMA)
            setEDS(snapshot.val().ESTADO_DE_FUNCIONAMIENTO)
          });
    },[])

    return(
        <>
            
            <Nav className="personal-nav justify-content-end">
              <Nav.Item>
                <Nav.Link><Button onClick={LogOut} variant="warning">LogOut</Button></Nav.Link>
              </Nav.Item>
            </Nav>
            
            <Container fluid>
                <Row>
                    <Col sm="12" md="6"className={"estado-col estado-funcionamiento-col "  + (EDS === true ? "activo" : "desactivado")} onClick={writeEDS}><div className="estado-text">Estado de sistema: {(EDS === true ? "ON" : "OFF")} </div></Col>
                    <Col sm="12" md="6"className={"estado-col estado-alarma-col " + (EDA === true ? "activo" : "desactivado")} onClick={writeEDA}><div className="estado-text">Estado de Alarma: {(EDA === true ? "ON" : "OFF")}</div></Col>
                </Row>
            </Container>

        </>
    )
}