import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';

import Login from './Components/Login';
import SetUp from './Components/SetUp';
import {auth} from './firebase.config/firebaseconfig.js'
import {onAuthStateChanged } from "firebase/auth";

function App() {

  const [Content , SetContent] = useState(<Login/>)

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        SetContent(<SetUp uid={uid}/>)
      } else {
        SetContent(<Login/>)
      }
    });
  },[])

  return (
    <div className="App">
      {Content}
    </div>
  );
}

export default App;
